import axios from "axios";


const apiPublicService = axios.create({
  baseURL: "https://merkez.bilim.tm/api/v1/centralized",
  headers:{
      "Content-Type": "application/json",
      "Accept":"application/json",
      "Centralized": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwdWJsaXNoZXIiOjV9.mo3h7Wegbt8sJKkYRAZ4HImYlc00YglECigTGCzgz10",
  }
})

export {
  apiPublicService,
}