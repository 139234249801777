import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { getSingleOffice } from '../../store/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import BlogSidebar from './sidebar';
import '../blog/blog.scss'
import { base } from '../../store/api';
import { Helmet } from 'react-helmet';

function EducationSingle() {
  let { id } = useParams();
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const lang = language === 'tm' ? 'tk' : language
    getSingleOffice(dispatch, id, lang, (err, res) => {
      if (err) console.log(err);
      setData(res?.data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, id])

  return (
    <>
      <Helmet>
        <title>{`${t("offices")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("offices")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              <Col xl={8} lg={12}>
                <article className="about-post blog-post" style={{ padding: '15px', boxShadow: '0 0 40px hsla(0,0%,51%,.1), 0 0 40px hsla(0,0%,51%,.1)' }}>
                  <div className='entry-content'>
                    <h4 className="entry-title pt-2 uppercase about-title">{Object.keys(data).length ? data.title : ''}</h4>

                  </div>
                  <div className="post-image education-post-image d-flex justify-content-center" style={{ margin: '25px auto' }}>
                    <img className="w-100" src={Object.keys(data).length ? `${base}${data.thumbnail}` : ''} alt="" />
                  </div>
                  <div className="entry-content">
                    <div className="entry-description w-100">
                      <div dangerouslySetInnerHTML={{ __html: Object.keys(data).length ? data.description : '' }}></div>
                    </div>
                  </div>
                </article>
              </Col>
              <Col xl={4} lg={8} md={8}>
                <BlogSidebar id={id} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default EducationSingle;
