import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './index.scss'
import { Helmet } from 'react-helmet';

function ContactUs() {
  const { t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{`${t("contact")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("contact")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper">
          <section className="page-ptb contact-page">
            <Container>
              <Row className="justify-content-lg-around align-items-top position-relative">
                <div className="col-xl-8 col-lg-7 col-md-12">
                  <div className="contact-area-wrapper mb-5 h-100">
                    <div className="google-map-area contact-map pt-100 mb-30 h-100">
                      <iframe title=' ' className='h-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1140.284936948898!2d58.36403151594203!3d37.95478015551915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f70016d54c8e501%3A0xae0bbb3431d3d200!2z0J3QsNGG0LjQvtC90LDQu9GM0L3Ri9C5INC40L3RgdGC0LjRgtGD0YIg0L7QsdGA0LDQt9C-0LLQsNC90LjRjyDQotGD0YDQutC80LXQvdC40YHRgtCw0L3QsA!5e0!3m2!1sen!2s!4v1706865892714!5m2!1sen!2s" ></iframe></div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-8">
                  <div className="sidebar-widget-wrapper">
                    <div className="sidebar__widget mb-30">
                      <div className="sidebar__widget-content">
                        <div className="rc__post-wrapper">
                          <div className="support-contact-inner">
                            <div className="support-item">
                              <div className="support-icon">
                                <img src='/images/footer/phone.svg' alt='phone' />
                              </div>
                              <div className="support-info-phone">
                                <span>{t("phone")}</span>
                                <div><Link to="tel:+993 12 36-19-25"> +993 12 36-19-25</Link> <Link to="tel:+993 12 36-19-26"> / 26</Link></div>
                                <div><Link to="tel:+993 12 50-31-84"> +993 12 50-31-84</Link></div>
                              </div>
                            </div>
                            <div className="support-item">
                              <div className="support-icon">
                                <img src='/images/footer/mail.svg' alt='mail' />
                              </div>
                              <div className="support-info-email">
                                <span>{t("email")}</span>
                                <Link to="mailto:iicmet@gov.tm">iicmet@bilim.tm</Link>
                                {/* <Link to="mailto:iicmet@sanly.tm">iicmet@sanly.tm</Link> */}
                                {/* <Link to="mailto:iicmet@hat.edu.tm">iicmet@hat.edu.tm</Link> */}
                              </div>
                            </div>
                            <div className="support-item">
                              <div className="support-icon">
                                <img src='/images/footer/location.svg' alt='location' />
                              </div>
                              <div className="support-info-location w-80">
                                <span>{t("location")}</span>
                                <Link to="#">744000, Türkmenistan, Aşgabatş., Bagtyýarlyk etraby, Magtymguly şaýoly, 136 jaý
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </Row>
            </Container>
          </section>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
