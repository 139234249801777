import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getSingleGallery } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/dist/umd/react-router-dom.development';
import ImageGallery from './imageGallery';
import { Helmet } from 'react-helmet';
import Loading from '../../component/Loading';
import { apiPublicService } from '../../api';

function GalleryDetails() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [gallery, setGallery] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const lang = language === 'tk' ? 'tm' : language
    setLoading(true)
    apiPublicService.get(`/image/${id}?lang=${lang}`)
      .then((req) => {
        const res = req.data
        let filteredSrc = []
        res?.images.forEach(item => filteredSrc.push(item.image_thumbnail))
        setGallery(filteredSrc)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])



  return (
    <>
      <Helmet>
        <title>{`${t("photo")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("photo")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        {loading ?
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", margin: "5% auto" }}>
            <Loading />
          </div>
          :
          <div className="content-wrapper page-template">
            <Container>
              <Row className="justify-content-center">
                <Col sm={12}>
                  <div className="grid-wrapper grid-lg-3 grid-md-2 grid-sm-1 grid-xs-1">
                    <ImageGallery
                      theme="gallery-style-2"
                      data={gallery}
                    />
                  </div>
                </Col>
              </Row>

            </Container>
          </div>
        }
      </div>
    </>
  );
}

export default GalleryDetails;
