import React from 'react';
import { Outlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterCopyright from './footer-copyright';
import FooterMenu from '../../widget/footer-menu';
import './footer.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer() {
  const { t } = useTranslation()

  const servicesMenu = [
    { id: 1, item: t("about"), url: '/about' },
    { id: 1, item: t("services"), url: '/services' },
    { id: 1, item: t("competitions"), url: '/competitions' },
    { id: 2, item: t("press"), url: '/news' },
    { id: 3, item: t("digital_services"), url: '/digital/e-school' },
    { id: 4, item: t("media"), url: '/photos' },
    { id: 4, item: t("faq"), url: '/faq' },
    { id: 4, item: t("contact"), url: '/contact' },
  ];

  const companyMenu = [
    { id: 1, item: t("cyber"), url: '/lessons' },
    { id: 2, item: t("network"), url: '/lessons' },
    { id: 3, item: t("programming"), url: '/lessons' },
    { id: 3, item: t("arduino"), url: '/lessons' },
    { id: 3, item: t("digital_economics"), url: '/lessons' },
  ];

  return (
    <>
      <footer className="site-footer footer-style-02">
        <div className="footer-main">
          <div className="footer-inner">
            <Container>
              <div className='logo-bar'>
                <div className='footer-logo d-flex align-items-center gap-2 justify-content-center'>
                  <img src='/images/logo.png' alt='' />
                  <div className='footer-title'>{t("iicimet")}<br /> {t("iicimet_short")}</div>
                </div>
                <div className='d-flex align-items-center gap-5 image-container'>
                  <Link to='https://education.gov.tm/' className='d-flex gap-2 align-items-center logo-link'>
                    <img alt='' src='/images/footer2.png' />
                    <div className='logo-title-ed'>Türkmenistanyň <br /> Bilim ministrligi </div>
                  </Link>
                  <Link to='https://sanly.tm/'><img alt='' src='/images/footer1.png' /></Link>
                  <Link to='https://e.gov.tm/'><img alt='' className='logo-3' src='/images/footer3.png' /></Link>
                </div>
              </div>
              <Row>

                <Col lg={4} md={6} className="mt-4 mt-md-0 ps-lg-5">
                  <FooterMenu
                    title={t("pages")}
                    col="col-2"
                    data={servicesMenu}
                  />
                </Col>
                <Col lg={4} sm={6} className="mt-4 mt-lg-0">
                  <FooterMenu title={t("e_lessons")} data={companyMenu} />
                </Col>
                <Col lg={4} sm={6} className="mt-4 mt-lg-0 contact-component">
                  <div className='widget footer-menu'>
                    <h3 className="widget-title">{t("contact")}</h3>
                  </div>
                  <div className='d-flex gap-2 align-items-center pb-1 flex-wrap'>
                    <div><img alt='' src='/images/footer/phone.svg' /></div>
                    <div>
                      <a href='tel:+993 12 36-19-25'>+993 12 36-19-25</a>
                      <a href='tel:+993 12 36-19-26'> / 26</a>
                    </div>
                  </div>
                  <a style={{ marginTop: -15, marginLeft: 23 }} href='tel:+993 12 50-31-84'>+993 65 50-31-84</a>
                  <a style={{ marginTop: -10, marginLeft: 23 }} href='tel:+993 65 50-49-61'>+993 65 50-49-61</a>
                  <div className='d-flex gap-2 align-items-center pb-1'>
                    <div><img alt='' src='/images/footer/mail.svg' /></div>
                    {/* <a href='mailto:iicmet@gov.tm'>iicmet@gov.tm</a> */}
                    <a href='mailto:iicmet@bilim.tmm'>iicmet@bilim.tm</a>
                  </div>
                  <a style={{ marginTop: -15, marginLeft: 23 }} href='mailto:iicmet@sanly.tm'>iicmet@sanly.tm</a>
                  <div className='d-flex gap-2 align-items-top'>
                    <div><img alt='' src='/images/footer/location.svg' /></div>
                    <div className='w-100'>744000, Türkmenistan, Aşgabat ş., Bagtyýarlyk etraby, Magtymguly şaýoly, 136 jaý</div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <FooterCopyright />
      </footer>
      <Outlet />
    </>
  );
}

export default Footer;
